import { ajax } from '../ajax';
import { AxiosResponse } from 'axios';
import { UserInfo } from 'types/entities/UserInfo';
import { UserInfoToPatch } from './UserInfoService/types';

const url = ajax.appBaseUrl;

export default class UserInfoService {
  static async getInfoV1() {
    return ajax.get<AxiosResponse<UserInfo>>(`${url}/oauth/user`);
  }

  static async getInfoV2() {
    return ajax.get<AxiosResponse<UserInfo>>(`${url}/oauthv2/user`);
  }

  static async patchInfo(data: UserInfoToPatch) {
    return ajax.patch<AxiosResponse<UserInfo>>(`${url}/oauthv2/user`, data);
  }
}
