import { useState } from 'react';

/**
 * Temporary hook for managing form field value and error.
 * It will become unnecessary after migrating to RHK.
 */
export default function useFormField<T>(initialValue?: T) {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState<string>();

  return {
    value,
    error,
    setValue,
    setError,
  };
}
