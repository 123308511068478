import { Card, GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { BaseButton } from 'app/components/buttons/BaseButton';
import { ActionsListItem, ActionsMenu } from 'app/components/menu';
import useHasAccessSharingPermissions from 'app/hooks/useHasAccessSharingPermissions';
import { getDefaultThumbnail } from 'app/pages/Equipment/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IEquipmentEntry } from 'types/IEquipmentEntry';
import capitalizeFirstLetter from 'utils/text/capitalizeFirstLetter';

interface ISharedEquipmentRow {
  equipment: IEquipmentEntry;
  isMobile: boolean;
  onRevokeOwnAccess: () => void;
}

function SharedEquipmentRow({
  equipment,
  isMobile,
  onRevokeOwnAccess,
}: ISharedEquipmentRow) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasAccess = useHasAccessSharingPermissions(equipment.role);

  const actions = useMemo<ActionsListItem[]>(() => {
    return [
      {
        label: t('access_sharing.role_based_actions.add_new_user'),
        action: () =>
          navigate(`/access/shared-equipment/${equipment.id}/add-new-user`),
        exclude: !hasAccess('addNewUser'),
      },
      // {
      //   label: t('access_sharing.role_based_actions.view_logs'),
      //   action: () => {},
      //   exclude: !hasAccess('viewLogs'),
      // },
      {
        label: t('access_sharing.role_based_actions.revoke_my_access'),
        action: onRevokeOwnAccess,
        exclude: !hasAccess('revokeOwnAccess'),
      },
    ];
  }, [hasAccess, t]);

  function handleOpenDetailsPage() {
    navigate('/access/shared-equipment/' + equipment.id);
  }

  return (
    <>
      {!isMobile ? (
        <BaseButton
          className="access-sharing-table-row"
          onClick={handleOpenDetailsPage}
        >
          <div className="picture-and-description">
            <img
              src={
                equipment.modelIconUrl ||
                getDefaultThumbnail(equipment.type, equipment.subcategory)
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = getDefaultThumbnail(
                  equipment.type,
                  equipment.subcategory,
                );
              }}
            />
            <div className="equipment-details">
              <span
                className="equipment-list-nickname"
                title={equipment.nickName || equipment.model}
              >
                {(equipment.nickName &&
                  (equipment.nickName.length > 25
                    ? equipment.nickName.substring(0, 25).concat('...')
                    : equipment.nickName)) ||
                  equipment.model}
              </span>
              <GridLayout
                rows={[{ height: '5' }, { height: '5' }]}
                cols={[{ width: '30%' }, { width: '70%' }]}
                className="inline-label-text"
              >
                <GridLayoutItem className="label">
                  {t('equipment_model')}
                </GridLayoutItem>
                <GridLayoutItem className="text">
                  {equipment.model}
                </GridLayoutItem>
                <GridLayoutItem className="label">
                  {equipment.identifierType === 'Pin'
                    ? t('equipment_pin')
                    : t('equipment_serial')}
                </GridLayoutItem>
                <GridLayoutItem className="text">
                  {equipment.pinOrSerial}
                </GridLayoutItem>
              </GridLayout>
            </div>
          </div>
          <div className="pending-details">
            {t(equipment.role?.toLowerCase() || '').toUpperCase()}
          </div>
          <div className="pending-details">User User</div>
          <div className="pending-details">user@gmail.com</div>
          <div className="pending-details">4 users</div>

          <ActionsMenu actions={actions} />
        </BaseButton>
      ) : (
        <div
          className="access-sharing-table-card cursor-pointer"
          onClick={handleOpenDetailsPage}
        >
          <div className="row">
            <div className="col-xxs-2">
              <img
                src={
                  equipment.modelIconUrl ||
                  getDefaultThumbnail(equipment.type, equipment.subcategory)
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = getDefaultThumbnail(
                    equipment.type,
                    equipment.subcategory,
                  );
                }}
                alt=""
                className="equipment-list-image"
                style={{ height: '60px', width: '100%' }}
              />
            </div>
            <div className="col-xxs-9">
              <span
                className="equipment-list-nickname"
                title={equipment.nickName || equipment.model}
              >
                {equipment.nickName || equipment.model}
              </span>
              <GridLayout
                rows={[{ height: '5' }, { height: '5' }]}
                cols={[{ width: '20%' }, { width: '80%' }]}
                className="inline-label-text"
              >
                <GridLayoutItem className="label">
                  {t('equipment_model')}
                </GridLayoutItem>
                <GridLayoutItem className="text">
                  {equipment.model}
                </GridLayoutItem>
                <GridLayoutItem className="label">
                  {equipment.identifierType === 'Pin'
                    ? t('equipment_pin')
                    : t('equipment_serial')}
                </GridLayoutItem>
                <GridLayoutItem className="text">
                  {equipment.pinOrSerial}
                </GridLayoutItem>
              </GridLayout>
            </div>
          </div>
          <div className="equipment-list-details">
            <div className="row-4">
              <div className="col-xxs-12">
                <Card className="access-sharing-equipment-details">
                  <div>
                    <span className="bolded-info">{t('owner')}</span>
                    <span className="vertical-line"></span>
                    <span className="bolded-info">Alexander Turner(Me)</span>
                  </div>
                  <div>andrew@example.ro</div>
                </Card>
              </div>
            </div>
            <div className="row-4">
              <div className="col-xxs-6">
                <Card className="access-sharing-equipment-details">
                  <div className="bolded-info">{t('my_access')}</div>
                  <div className="colored-info">
                    {capitalizeFirstLetter(
                      t(equipment.role?.toLowerCase() || ''),
                    )}
                  </div>
                </Card>
              </div>
              <div className="col-xxs-6">
                <Card className="access-sharing-equipment-details">
                  <div className="bolded-info">{t('shared_with')}</div>
                  <div>4 users</div>
                </Card>
              </div>
            </div>
            <div className="more-details-button">
              <ActionsMenu actions={actions} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SharedEquipmentRow;
