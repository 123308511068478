/**
 * Azure AD B2C tenant information
 */
import { PublicClientApplication, Configuration } from '@azure/msal-browser';

const tenantName = process.env.B2C_TENANT_NAME;
const clientId = process.env.B2C_CLIENT_ID;
const signInPolicy = 'B2C_1A_SEAMLESSMIGRATION_SIGNIN';
const signUpPolicy = 'B2C_1A_SEAMLESSMIGRATION_SIGNUP';
const changePasswordPolicy = 'B2C_1A_PASSWORDCHANGE';
const redirectUri = '/';
const postLogoutRedirectUri = '/';
const scopes = ['openid', 'profile', 'offline_access'];
const msalConfig: Configuration = {
  auth: {
    clientId: clientId || '',
    authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signInPolicy}`,
    knownAuthorities: [`${tenantName}.b2clogin.com`],
    redirectUri: redirectUri,
    postLogoutRedirectUri: postLogoutRedirectUri,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};
const loginRequest = (language: string) => {
  return {
    scopes: scopes,
    extraQueryParameters: {
      ui_locales: language,
    },
  };
};
const signUpRequest = (language: string) => {
  return {
    scopes: scopes,
    extraQueryParameters: {
      ui_locales: language,
    },
    authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${signUpPolicy}`,
  };
};
const changePasswordRequest = (language: string) => {
  return {
    scopes: scopes,
    extraQueryParameters: {
      ui_locales: language,
    },
    authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${changePasswordPolicy}`,
  };
};
const logOutRequest = (language: string) => {
  return {
    scopes: scopes,
    extraQueryParameters: {
      ui_locales: language,
    },
  };
};
const readTenant = {
  scopes: [`https://${tenantName}.onmicrosoft.com/${tenantName}api/read`],
};

export const msalInstance = new PublicClientApplication(msalConfig);

export {
  msalConfig,
  readTenant,
  loginRequest,
  signUpRequest,
  changePasswordRequest,
  logOutRequest,
};
