import { ajax } from 'ajax/ajax';
import { useMobileSettingsContext, useUserInfoContext } from 'context';
import { useEffect } from 'react';

/**
 * This hook will be useful for handling asynchronous tasks that require the application to wait for their completion,
 * until we switch to RTK Query.
 */
export default function useIsGlobalLoading() {
  const isLoggedIn = ajax.isLoggedIn();

  const { isLoading: isMobileSettingsLoading } = useMobileSettingsContext();
  const { refetch: fetchUserInfo, isLoading: isUserInfoLoading } =
    useUserInfoContext();

  useEffect(() => {
    if (isLoggedIn && !isMobileSettingsLoading) {
      fetchUserInfo();
    }
  }, [isLoggedIn, isMobileSettingsLoading, fetchUserInfo]);

  return isMobileSettingsLoading || isUserInfoLoading;
}
