import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { UserInfoService } from 'ajax/services';
import usePhoneMask from 'app/hooks/form-fields/usePhoneMask';
import useClickOutside from 'app/hooks/useClickOutside';
import useTryMutate from 'app/hooks/useTryMutate';
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  close: () => void;
}

export default function EditPhoneNumberDialog({
  initialValue,
  onChange,
  close,
}: Props) {
  const { t } = useTranslation();

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(close);

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const {
    maskedValue: phoneNumber,
    unmaskedValue: unmaskedPhoneNumber,
    onChange: onChangePhoneNumber,
  } = usePhoneMask(initialValue);

  const handleUpdate = useTryMutate(
    () => {
      setIsLoading(true);
      return UserInfoService.patchInfo({ phoneNumber: unmaskedPhoneNumber });
    },
    {
      onComplete: (res, err, arg, api) => {
        setIsLoading(false);
        if (err) {
          api.showErrorModal();
        } else {
          api.successNotification(t('success_phone_number_update'));
          onChange(unmaskedPhoneNumber);
          close();
        }
      },
    },
  );

  function validatePhone() {
    if (!unmaskedPhoneNumber) {
      setPhoneNumberError(t('phone_is_required'));
      return false;
    }
    if (unmaskedPhoneNumber.length < 10) {
      setPhoneNumberError(t('phone_incorrect_error'));
      return false;
    }

    return true;
  }

  return (
    <Dialog className="modal-form">
      <div className="modal-container relative">
        <span
          className="back-button absolute"
          onClick={() => {
            close();
          }}
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <>
          <h2>{t('edit_phone_number')}</h2>
          <div className={`form-group ${phoneNumberError && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('phone_number')} *</span>
              </div>

              <div className="form-control">
                <Input
                  id="phoneNumber"
                  tabIndex={4}
                  name="phoneNumber"
                  autoComplete="off"
                  placeholder={t('enter_your_phone_number')}
                  value={phoneNumber}
                  onChange={e => {
                    const value = e.target.value as string;
                    onChangePhoneNumber(value);
                    setPhoneNumberError('');
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>

            <div className="form-error">
              {phoneNumberError && (
                <Error id={'nameError'}>{phoneNumberError}</Error>
              )}
            </div>
          </div>

          <Button
            themeColor="primary"
            onClick={() => validatePhone() && handleUpdate()}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('save')
            )}
          </Button>
        </>
      </div>
    </Dialog>
  );
}
