import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import NoSharedFleets from '../../../../../assets/images/ill_empty_access_sharing_fleets.png';
import { Button } from '@progress/kendo-react-buttons';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { AcessSharingFilters } from '../..';
import SharedFleetsRow from './SharedFleetsRow';
import { Input } from '@progress/kendo-react-inputs';
import SearchIcon from 'assets/images/ic_search.svg';
import { ic_close_small_black as BlackCloseIcon } from 'app/widgets/SVGs';
import { useNavigate } from 'react-router-dom';
import { dummyFleetsData } from 'app/constants/access-sharing';
import {
  EditFleetDialog,
  EditFleetNameDialog,
} from 'app/components/access-sharing/dialogs';
import useTryMutate from 'app/hooks/useTryMutate';
import { ShareAccessEquipmentGuideProvider } from 'app/hooks/access-sharing';
import { DeleteDialog } from 'app/components/dialogs';

interface ISharedFleets {
  isMobile: boolean;
}

function SharedFleets(props: ISharedFleets) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // TODO It will be replaced with data from useUserInfo hook.
  const userEmail =
    localStorage.getItem('kubota_user_name') ?? 'user@email.com';

  const { equipmentListData: data } = React.useContext(EquipmentListContext);
  const [searchValue, setSearchValue] = React.useState<string>('');

  const [isExpanded, setIsExpanded] = React.useState(false);

  const [selectedFleet, setSelectedFleet] = useState<any>();
  const [isEditFleetNameDialogOpen, setIsEditFleetNameDialogOpen] =
    useState(false);
  const [isEditFleetDialogOpen, setIsEditFleetDialogOpen] = useState(false);
  const [isDeleteFleetDialogOpen, setIsDeleteFleetDialogOpen] = useState(false);
  const [isRevokeOwnAccessDialogOpen, setIsRevokeOwnAccessDialogOpen] =
    useState(false);

  const [filter, setFilter] = React.useState(AcessSharingFilters.All);

  const handleChangeFleetName = useTryMutate(
    (name: string) => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successNotification(
          t('access_sharing.notifications.fleet_name_successfully_updated'),
        );
        setIsEditFleetNameDialogOpen(false);
      },
    },
  );

  const handleDeleteFleet = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successNotification(
          t('access_sharing.notifications.fleet_successfully_deleted'),
        );
        setIsDeleteFleetDialogOpen(false);
      },
    },
  );
  const handleRevokeAccess = useTryMutate(
    () => {
      return Promise.resolve(1);
    },
    {
      onComplete: (res, err, arg, api) => {
        api.successSnackbarNotification(
          t('access_sharing.snackbars.your_access_revoked_successfully', {
            machineName: selectedFleet.name,
          }),
        );
        setIsRevokeOwnAccessDialogOpen(false);
      },
    },
  );

  return (
    <>
      <div className="access-sharing-subcontainer">
        <div className="access-sharing-subcontainer-header">
          {data.equipmentList?.length == 0 ? (
            <h3>{t('shared_fleets_title')}</h3>
          ) : (
            <div className="access-sharing-subcontainer-header-content">
              <h3>{t('shared_fleets_title')}</h3>
              <div className="site-search open">
                <span className="search-icon">
                  <img alt="Search" src={SearchIcon}></img>
                </span>
                <div className="search-input">
                  <Input
                    name="equipment_fleets_search"
                    value={searchValue}
                    placeholder={t('shared_equipment_search_placeholder')}
                    onChange={newValue => {
                      setSearchValue(newValue.value);
                    }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  />
                  {searchValue && (
                    <span
                      className="search-icon-close"
                      onClick={() => {
                        setSearchValue('');
                      }}
                    >
                      <BlackCloseIcon />
                    </span>
                  )}
                </div>
              </div>
              <div className="access-sharing-filters">
                <div
                  className={`access-sharing-filter-item ${
                    filter == AcessSharingFilters.All ? 'active' : ''
                  }`}
                  onClick={() => setFilter(AcessSharingFilters.All)}
                >
                  <div>{t('all')}</div>
                </div>
                <div
                  className={`access-sharing-filter-item ${
                    filter == AcessSharingFilters.OwnedByMe ? 'active' : ''
                  }`}
                  onClick={() => setFilter(AcessSharingFilters.OwnedByMe)}
                >
                  <div>{t('owned_by_me')}</div>
                </div>
                <div
                  className={`access-sharing-filter-item ${
                    filter == AcessSharingFilters.SharedWithMe ? 'active' : ''
                  }`}
                  onClick={() => setFilter(AcessSharingFilters.SharedWithMe)}
                >
                  <div>{t('shared_with_me')}</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="access-sharing-subcontainer-body">
          {data.equipmentList?.length == 0 ? (
            <div className="access-sharing-subcontainer-body-empty">
              <div className="access-sharing-body-picture">
                <img src={NoSharedFleets}></img>
              </div>
              <div className="access-sharing-body-text">
                <h4>{t('shared_fleets_no_fleets_title')}</h4>
                <p>{t('shared_fleets_no_fleets_text')}</p>
              </div>
            </div>
          ) : (
            <div className="access-sharing-subcontainer-body-table">
              <div className="access-sharing-subcontainer-body-table-header">
                <div>{t('fleet')}</div>
                <div>{t('my_access')}</div>
                <div>{t('owner')}</div>
                <div></div>
                <div>{t('shared_with')}</div>
                <div></div>
              </div>
              <div className="access-sharing-subcontainer-body-table-body">
                {/* {data.equipmentList?.map(equipment => { */}
                {dummyFleetsData.map(fleet => (
                  <SharedFleetsRow
                    key={fleet.id}
                    fleet={fleet}
                    isMobile={props.isMobile}
                    onEditFleet={() => {
                      setSelectedFleet(fleet);
                      setIsEditFleetDialogOpen(true);
                    }}
                    onEditFleetName={() => {
                      setSelectedFleet(fleet);
                      setIsEditFleetNameDialogOpen(true);
                    }}
                    onDeleteFleet={() => {
                      setSelectedFleet(fleet);
                      setIsDeleteFleetDialogOpen(true);
                    }}
                    onRevokeOwnAccess={() => {
                      setSelectedFleet(fleet);
                      setIsRevokeOwnAccessDialogOpen(true);
                    }}
                  />
                ))}
              </div>
            </div>
          )}
          <div className="access-sharing-subcontainer-body-buttons-container">
            <Button
              className="content-button round-button"
              themeColor="tertiary"
              onClick={() => navigate('create-fleet')}
            >
              {t('shared_fleets_create_button')}
            </Button>
            <Button
              className="content-button round-button"
              themeColor="secondary"
              onClick={() => navigate('share-fleet')}
            >
              {t('shared_fleets_share_button')}
            </Button>
          </div>
        </div>
      </div>

      {isEditFleetNameDialogOpen && (
        <EditFleetNameDialog
          initialValue={selectedFleet.name}
          onChange={handleChangeFleetName}
          onClose={() => setIsEditFleetNameDialogOpen(false)}
        />
      )}

      <ShareAccessEquipmentGuideProvider>
        {isEditFleetDialogOpen && (
          <EditFleetDialog onClose={() => setIsEditFleetDialogOpen(false)} />
        )}
      </ShareAccessEquipmentGuideProvider>

      {isDeleteFleetDialogOpen && (
        <DeleteDialog
          open
          title={t('access_sharing.dialogs.delete_fleet.title', {
            name: selectedFleet.name,
          })}
          confirmationLabel={t(
            'access_sharing.dialogs.delete_fleet.actions.delete',
          )}
          onClose={() => setIsDeleteFleetDialogOpen(false)}
          onConfirm={handleDeleteFleet}
        />
      )}

      {isRevokeOwnAccessDialogOpen && (
        <DeleteDialog
          open
          title={t('revoke_access_confirmation', {
            email: userEmail,
            machineName: selectedFleet.name,
          })}
          onClose={() => setIsRevokeOwnAccessDialogOpen(false)}
          onConfirm={handleRevokeAccess}
        />
      )}
    </>
  );
}

export default SharedFleets;
