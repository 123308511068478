/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import '@progress/kendo-theme-default/dist/all.css';
import 'styles/root.less';

// Import root app
import { App } from 'app';

import { HelmetProvider } from 'react-helmet-async';

import { configureAppStore } from 'store/configureStore';

import reportWebVitals from 'reportWebVitals';

// Initialize languages
import './locales/i18n';
import { ReactContextContainer } from 'app/components/ReactContexts/reactContextContainer';

import ReactGA from 'react-ga4';
import { GlobalVideo } from 'app/components/GlobalVideo';
import { HashRouter } from 'react-router-dom';

import { MsalProvider } from '@azure/msal-react';
import { msalInstance } from './msalConfig/msalConfig';

ReactGA.initialize([
  {
    trackingId: process.env.GA_TRACKING_CODE || '',
  },
]);

const store = configureAppStore();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

//initialize msal instance
root.render(
  <Provider store={store}>
    <HelmetProvider>
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <HashRouter>
            <ReactContextContainer>
              <GlobalVideo>
                <App />
              </GlobalVideo>
            </ReactContextContainer>
          </HashRouter>
        </MsalProvider>
      </React.StrictMode>
    </HelmetProvider>
  </Provider>,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
