import { AxiosResponse } from 'axios';
import { useState, useEffect, useCallback } from 'react';

interface RequestState<T> {
  isUninitialized: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: T | null;
  error: Error | null;
}

interface Options {
  lazyLoading?: boolean;
}

export interface UseHttpRequestReturn<T> extends RequestState<T> {
  refetch: (...args: any[]) => Promise<void>;
}

export default function useHttpRequest<T>(
  fetchFunction: (...args: any[]) => Promise<AxiosResponse<T>>,
  options?: Options,
): UseHttpRequestReturn<T> {
  const [state, setState] = useState<RequestState<T>>({
    isUninitialized: true,
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: null,
    error: null,
  });

  const makeRequest = useCallback(
    async (...args: Parameters<typeof fetchFunction>) => {
      setState({
        isUninitialized: false,
        isLoading: true,
        isSuccess: false,
        isError: false,
        data: null,
        error: null,
      });

      try {
        const response = await fetchFunction(...args);
        setState({
          isUninitialized: false,
          isLoading: false,
          isSuccess: true,
          isError: false,
          data: response.data,
          error: null,
        });
      } catch (err) {
        setState({
          isUninitialized: false,
          isLoading: false,
          isSuccess: false,
          isError: true,
          data: null,
          error: err as Error,
        });
      }
    },
    [fetchFunction],
  );

  useEffect(() => {
    if (!options?.lazyLoading) {
      makeRequest();
    }
  }, [makeRequest]);

  return { ...state, refetch: makeRequest };
}
