import * as React from 'react';
import { EquipmentListContext } from 'app/components/ReactContexts/equipmentListContext';
import { ajax } from 'ajax/ajax';
import { Link } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@progress/kendo-react-buttons';
import EmptyEquipmentImage from 'assets/images/ill_empty_equipment.png';
import MenuResourcesIcon from 'assets/images/ic_menu_resources.svg';
import DealersIcon from 'assets/images/ic_menu_dealers.svg';
import AboutIcon from 'assets/images/ic_menu_about.svg';
import { Dialog } from '@progress/kendo-react-dialogs';
import { AddEquipment } from '../AddEquipment';
import { LayoutContext } from 'app/components/ReactContexts/layoutContext';
import { WelcomePopUp } from './WelcomePopUp';
import SecondaryBtnIcon from 'assets/images/ic_btn_add.svg';
import { useUserInfoContext } from 'context';
import useUsername from 'app/hooks/user-info/useUsername';

export function EquipmentContentEmpty() {
  const { equipmentListData: data, setEquipmentListData: setData } =
    React.useContext(EquipmentListContext);
  const { data: userInfo } = useUserInfoContext();
  const username = useUsername();
  const [showAddDialog, setShowAddDialog] = React.useState<boolean>(false);
  const { setClassNames, removeClassNames } = React.useContext(LayoutContext);

  // mount
  React.useEffect(() => {
    let index: number = setClassNames('layout-fullwidth layout-grey');

    return () => removeClassNames(index);
  }, []);

  const updateEquipmentList = (
    equipmentList,
    selectedIndex,
    selectedEquipment,
  ) => {
    if (selectedIndex >= 0) {
      setData({
        ...data,
        equipmentList,
        selectedIndex,
        selectedEquipment,
      });
    } else {
      setData({
        ...data,
        equipmentList,
      });
    }
  };

  const { t } = useTranslation();

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (event.target.className === 'k-overlay') {
        event.stopPropagation();
        setShowAddDialog(false);
      }
    }

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  });

  return (
    <>
      {userInfo?.email_verified === false && (
        <div className="popup-container">
          <WelcomePopUp />
        </div>
      )}
      {showAddDialog && (
        <Dialog className="modal-equipment modal-fixed-button">
          <AddEquipment
            updateEquipmentList={updateEquipmentList}
            close={() => setShowAddDialog(false)}
          />
        </Dialog>
      )}
      <h2>{t('welcome_to_myKubota')}</h2>
      {ajax.isLoggedIn() ? (
        <h3 className="equipment-content-empty-username">{username}</h3>
      ) : (
        <h5>{t('monitor_your_equipment')}</h5>
      )}
      <div className="row equipment-content-empty-main_content">
        <div className="col-sm-5 equipment-content-empty-left_component">
          <h3 className="equipment-content-empty-subtitle">
            {t('no_machine_or_attachments_yet')}
          </h3>
          <p className="equipment-content-empty-text_explanation">
            <Trans i18nKey="no_equipment_message" />
          </p>
          <img src={EmptyEquipmentImage} />
          <div className="button-wrapper">
            <Button
              style={{ margin: '10px' }}
              onClick={() => setShowAddDialog(true)}
              themeColor="secondary"
            >
              <span className="btn_icon">
                <img src={SecondaryBtnIcon} alt="Secodary btn icon" />
              </span>
              <span>{t('AddEquipment.Title')}</span>
            </Button>
          </div>
        </div>

        <div className="col-sm-7 equipment-content-empty-right_component">
          <h4 className="equipment-content-empty-subtitle">
            {t('myKubota_utilities')}
          </h4>
          <div>
            <Link to="/resources" className="equipment-content-empty-menu-item">
              <img
                className="equipment-content-empty-menu-item-icon"
                src={MenuResourcesIcon}
              />
              <span>{t('resources_manuals_and_guides')}</span>
            </Link>
          </div>
          <div>
            <Link className="equipment-content-empty-menu-item" to="/dealers">
              <img
                className="equipment-content-empty-menu-item-icon"
                src={DealersIcon}
              />
              <span>{t('dealers_near_you')}</span>
            </Link>
          </div>
          <div>
            <Link to="/about" className="equipment-content-empty-menu-item">
              <img
                className="equipment-content-empty-menu-item-icon"
                src={AboutIcon}
              />
              <span>{t('about_myKubota')}</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
