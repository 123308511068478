import { MobileSettings } from 'types/entities/MobileSettings';
import { ajax } from '../ajax';
import { AxiosResponse } from 'axios';
import assert from 'assert';

const url = ajax.appBaseUrl;

export default class MobileSettingsService {
  static async getSettings(): Promise<AxiosResponse<MobileSettings>> {
    const version = process.env.VERSION;
    const oauthClientId = process.env.OAUTH_CLIENT_ID;

    assert(version, 'process.env.VERSION not found');
    assert(oauthClientId, 'process.env.OAUTH_CLIENT_ID not found');

    return ajax.get<AxiosResponse<MobileSettings>>(
      `${url}/api/mobile/settings`,
    );
  }
}
