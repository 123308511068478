import { useUserInfoContext } from 'context';

export default function useUsername() {
  const { data } = useUserInfoContext();

  const username =
    data?.first_name && data?.last_name
      ? `${data?.first_name} ${data?.last_name}`
      : data?.email;

  return username;
}
