import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import useClickOutside from 'app/hooks/useClickOutside';
import { useState } from 'react';
import useFormField from 'app/hooks/form-fields/useFormField';
import useTryMutate from 'app/hooks/useTryMutate';
import { UserInfoService } from 'ajax/services';

interface Props {
  initialValue: string;
  onChange: (value: string) => void;
  close: () => void;
}

export default function EditLastNameDialog({
  initialValue,
  onChange,
  close,
}: Props) {
  const { t } = useTranslation();

  // Note: there's a point in creating a wrapper around the Dialog and adding an onClickOutside prop
  useClickOutside(close);

  const { value, setValue, error, setError } = useFormField(initialValue);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdate = useTryMutate(
    () => {
      setIsLoading(true);
      return UserInfoService.patchInfo({ lastName: value });
    },
    {
      onComplete: (res, err, arg, api) => {
        setIsLoading(false);
        if (err) {
          api.showErrorModal();
        } else {
          api.successNotification(
            t('settings.dialogs.edit_last_name.success_name_update'),
          );
          onChange(value || '');
          close();
        }
      },
    },
  );

  function validateName() {
    if (!value) {
      setError(t('settings.dialogs.edit_last_name.field_is_required'));
      return false;
    }

    return true;
  }

  return (
    <Dialog className="modal-form">
      <div className="modal-container relative">
        <span
          className="back-button absolute"
          onClick={() => {
            close();
          }}
        >
          <svg
            id="ic_nav_back"
            xmlns="http://www.w3.org/2000/svg"
            width="48"
            height="48"
            viewBox="0 0 48 48"
          >
            <rect id="Box" width="48" height="48" fill="none" />
            <path
              id="ic_nav_up"
              d="M16.465,8.482H1.483"
              transform="translate(15.518 15.518)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
            <path
              id="Path_470"
              data-name="Path 470"
              d="M23.676,16.018,16.2,23.748l7.477,7.73"
              transform="translate(0.324 0.252)"
              fill="none"
              stroke="#1a1a1a"
              strokeLinecap="round"
              strokeWidth="2"
            />
          </svg>
        </span>
        <>
          <h2>{t('settings.dialogs.edit_last_name.title')}</h2>
          <div className={`form-group ${error && 'error'}`}>
            <div className="form-content">
              <div className="form-label">
                <span>{t('settings.dialogs.edit_last_name.label')} *</span>
              </div>
              <div className="form-control">
                <Input
                  id="lastName"
                  name="lastName"
                  autoComplete="off"
                  maxLength={45}
                  placeholder={t('settings.dialogs.edit_last_name.placeholder')}
                  value={value}
                  onChange={event => {
                    setValue(event.target.value as string);
                    setError('');
                  }}
                  disabled={isLoading}
                />
              </div>
            </div>
            <div className="form-error">
              {error && <Error id={'error'}>{error}</Error>}
            </div>
          </div>
          <Button
            themeColor="primary"
            onClick={() => validateName() && handleUpdate()}
            disabled={isLoading}
          >
            {isLoading ? (
              <div className="loading_icon loading_icon-btn">
                <div className="css-icon"></div>
              </div>
            ) : (
              t('save')
            )}
          </Button>
        </>
      </div>
    </Dialog>
  );
}
