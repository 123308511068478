import { useEffect, useState } from 'react';

const COUNTRY_CODE = '+1';

// Note: think about refactoring this hook, in case we need more flexibility (passing various phone formats
// or even don't stop at numbers and make the hook general - useMask()
export default function usePhoneMask(initialValue?: string) {
  const [maskedValue, setMaskedValue] = useState(format(initialValue ?? ''));
  const [unmaskedValue, setUnmaskedValue] = useState(initialValue ?? '');

  useEffect(() => {
    if (initialValue) {
      setMaskedValue(format(initialValue));
      setUnmaskedValue(initialValue);
    }
  }, [initialValue]);

  function format(value: string) {
    const unmaskedValue = unmaskValue(value);

    let formattedInput = '';

    if (unmaskedValue.length > 0) {
      formattedInput += '(' + unmaskedValue.substring(0, 3);
    }
    if (unmaskedValue.length >= 4) {
      formattedInput += ') ' + unmaskedValue.substring(3, 6);
    }
    if (unmaskedValue.length >= 7) {
      formattedInput += '-' + unmaskedValue.substring(6, 10);
    }

    return formattedInput;
  }

  function onChange(value: string) {
    console.log({ value });

    setMaskedValue(format(value));

    const unmaskedValue = unmaskValue(value);
    setUnmaskedValue(unmaskedValue);
  }

  return {
    maskedValue,
    unmaskedValue,
    onChange,
  };
}

function unmaskValue(value: string) {
  return value.replace(COUNTRY_CODE, '').replace(/\D/g, '');
}
