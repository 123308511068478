import React, { createContext, useContext } from 'react';
import useHttpRequest, {
  UseHttpRequestReturn,
} from 'app/hooks/http/useHttpRequest';
import { UserInfoService } from 'ajax/services';
import { UserInfo } from 'types/entities/UserInfo';
import useFlag from 'app/hooks/useFlag';
import { FeatureName } from 'types/entities/MobileSettings';

export type Context = UseHttpRequestReturn<UserInfo>;

interface Props {
  children: React.ReactNode;
}

const UserInfoContext = createContext<Context>({} as Context);

export function UserInfoProvider({ children }: Props) {
  const isSingleSignOnEnabled = useFlag(FeatureName.SINGLE_SIGN_ON);

  const userInfoRequest = useHttpRequest(
    isSingleSignOnEnabled
      ? UserInfoService.getInfoV2
      : UserInfoService.getInfoV1,
    {
      lazyLoading: true,
    },
  );

  return (
    <UserInfoContext.Provider value={userInfoRequest}>
      {children}
    </UserInfoContext.Provider>
  );
}

const useUserInfoContext = () => useContext(UserInfoContext);
export default useUserInfoContext;
