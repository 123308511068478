import * as React from 'react';
import { useMemo } from 'react';

interface IAvatarProps {
  name?: string;
}

export function Avatar(props: IAvatarProps) {
  const alias = useMemo(() => {
    const { name } = props;

    if (!name) {
      return '';
    }

    const namePieces = name.split(' ');
    return namePieces.length > 1
      ? namePieces[0][0] + namePieces[namePieces.length - 1][0]
      : namePieces[0][0];
  }, [props.name]);

  const colorMap = letter => {
    switch (letter) {
      case 'A':
      case 'B':
        return '#DC4405';
      case 'C':
      case 'D':
        return '#E88F26';
      case 'E':
      case 'F':
        return '#EFB10A';
      case 'G':
      case 'H':
        return '#1AAD7F';
      case 'I':
      case 'J':
        return '#00A8A9';
      case 'K':
      case 'L':
        return '#008486';
      case 'M':
      case 'N':
        return '#0681FD';
      case 'O':
      case 'P':
        return '#1759D5';
      case 'Q':
      case 'R':
        return '#6C439B';
      case 'S':
      case 'T':
        return '#AA35A6';
      case 'U':
      case 'V':
        return '#BF0C5F';
      case 'W':
      case 'X':
        return '#915C75';
      case 'Y':
      case 'Z':
        return '#484F65';
      default:
        return '#1a1a1a';
    }
  };

  return (
    <span className="avatar-circle">
      <span
        className="avatar-icon"
        style={{ backgroundColor: colorMap(alias[0]) }}
      >
        <span>{alias}</span>
      </span>
    </span>
  );
}
